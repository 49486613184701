/* eslint-disable react/prop-types */
import React from "react";
//import Img from "gatsby-image";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SkuCardDetails from "../components/Products/SkuCardDetails";

// state = {
//   stripe: null,
// };
// componentDidMount() {
//   const stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
//   this.setState({ stripe });
// }

const DetailsTemplate = ({ data }) => (
  <Layout>
    <SEO
      keywords={[
        `punk records`,
        `punkrockvideos`,
        `punkrockvideos.com`,
        `punk`,
        `punkrock`,
        `records`,
        `poppunk`,
        `emo`,
        `garage rock`,
        `7 inchs`,
        `vinyl records`,
        `pop punk`,
        `out of print records`,
        `color vinyl`,
      ]}
      title={data.stripeSku.attributes.name}
      description={data.stripeSku.attributes.name}
    />
    <SkuCardDetails sku={data.stripeSku} />
  </Layout>
);

export const query = graphql`
  query($id: String!) {
    stripeSku(id: { eq: $id }) {
      id
      price
      currency
      image
      attributes {
        name
      }
      localFiles {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;

// const DetailsTemplate = ({ pageContext }) => {
//   console.log(pageContext);
//   const { sku } = pageContext;
//   return (
//     <Layout>
//       <h1>{name}</h1>
//       <SkuCard sku={sku} stripe={stripe} />
//     </Layout>
//   );
// };

export default DetailsTemplate;
